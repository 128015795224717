import { CompanyDto } from "../companies.service.dto";
import { fakeIdGenerator, getManyRandom } from "@/service/mocks";
import * as faker from "faker";
import { sharesClassesData } from "@/service/share-classes/__mocks__/data";

const companyId = fakeIdGenerator();

export const companiesData: Array<CompanyDto> = new Array(10).fill({}).map(x => ({
    id: companyId(),
    name: faker.company.companyName(),
    sharePrice: faker.datatype.number({ min: 1, max: 1000 }),
    taxId: faker.finance.routingNumber(),
    shares: faker.datatype.number({ min: 100, max: 100000 }),
    taxPercent: faker.datatype.number({ min: 8, max: 25 }),
    classes: getManyRandom(sharesClassesData, 2),
    valuation: {
        actualMarketValue: faker.datatype.number({ min: 1000000, max: 5000000 }),
        unrestrictedMarketValue: faker.datatype.number({ min: 1000000, max: 5000000 }),
        expDate: faker.date.future(1),
    },
    address : {
        id: faker.datatype.uuid(),
        city: faker.address.city(),
        country: faker.address.country(),
        streetAddress: faker.address.streetAddress(),
        state: faker.address.state(),
        postCode: faker.address.zipCode()
    },
    incorporationDate: faker.date.past(),
    taxOffice: faker.datatype.string(),
    companyType: faker.datatype.string(),
    accountingStartDate: faker.date.past(),
    accountingEndDate: faker.date.future(),
    isFree: true,
    crnNumber: faker.datatype.string(),
    distinctiveName: faker.datatype.string()
}));