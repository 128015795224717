import { rest } from 'msw'
import * as faker from "faker";
import { sharesIssuancesData } from "./data";
import { NewSharesIssuanceDto, SharesIssuanceAnalysisDto, SharesIssuanceDto } from '../shares-issuances.service.dto';
import { getOneById, getOneRandom } from '@/service/mocks';
import { stakeholdersData } from '@/service/stakeholders/__mocks__/data';

export const sharesIssuanceshandlers = [

  rest.get<any, Array<SharesIssuanceDto>>("*/api/shares-issuances", (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(sharesIssuancesData));
  }),



  rest.get<any, Array<SharesIssuanceAnalysisDto>>("*/api/shares-issuances/analysis", (req, res, ctx) => {

    const result: Array<SharesIssuanceAnalysisDto> = sharesIssuancesData.map(x => {
      return {
        ...x,
        stakeholder: getOneRandom(stakeholdersData),
        accCount: faker.datatype.number({ min: 1, max: 1000 }),
        profit: faker.datatype.number({ min: 1, max: 1000 }),
        profitAfterTax: faker.datatype.number({ min: 1, max: 1000 }),
        currentPrice: faker.datatype.number({ min: 1, max: 1000 }),
        totalValue: faker.datatype.number({ min: 1, max: 1000 })
      }
    })

    return res(ctx.status(200), ctx.json(result));

  }),

  rest.get<any, SharesIssuanceDto>("*/api/shares-issuances/:id", (req, res, ctx) => {
    const { id } = req.params;
    const result = getOneById(sharesIssuancesData, id);
    if (result.item) {
      result.item.stakeholderId = "2";
      result.item.shareholderFile = {
        createdDate: faker.date.past(),
        mimeType: "application/pdf",
        name: "My Shareholder File.pdf",
        type: "Resolution",
        id: "12345",
        status: "pending"
      }


      return res(ctx.status(200), ctx.json(result.item));
    }
    else
      return res(ctx.status(404));
  }),

  rest.post<NewSharesIssuanceDto, SharesIssuanceDto>("*/api/shares-issuances", (req, res, ctx) => {
    return res(ctx.status(200)) // TODO: Return created resource
  }),

  rest.get<any, SharesIssuanceAnalysisDto>("*/api/shares-issuances/analysis/:id", (req, res, ctx) => {
    const { id } = req.params;
    const result = getOneById(sharesIssuancesData, id)
    if (result.item) {
      const analysis = {
        ...result.item,
        stakeholder: getOneRandom(stakeholdersData),
        accCount: faker.datatype.number({ min: 1, max: 1000 }),
        profit: faker.datatype.number({ min: 1, max: 1000 }),
        profitAfterTax: faker.datatype.number({ min: 1, max: 1000 }),
        currentPrice: faker.datatype.number({ min: 1, max: 1000 }),
        totalValue: faker.datatype.number({ min: 1, max: 1000 })
      }
      return res(ctx.status(200), ctx.json(analysis));
    }
    else
      return res(ctx.status(404));
  }),

];

