import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import { i18n } from "./i18n";

Vue.use(Vuetify);

export default new Vuetify({

  lang: {
    t: (key, ...params) => i18n.t(key, params).toString(),
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#4C8BF5",
        secondary: "#1C6CF2",
        accent: "#b74cf5",
        error: "#ff5722",
        warning: "#ff9800",
        info: "#009688",
        success: "#4caf50"
      },
    },
  },
});
