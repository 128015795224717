import vuetify from 'vuetify/src/locale/en'
export default {
  $vuetify: vuetify,
  app: {
    logout: "Log out",
  },
  $validator: {
    messages: {
      required: 'The field is required',
      min: 'The field should be at least {length} characters',
      max: 'The field should be at the most {length} characters',
      min_value: 'Min allowed value is {min}',
      max_value: 'Max allowed value is {max}',
      numeric: 'The field should contain only numbers',
      vestingPercent: 'The field should be a numeric value from 1 to 100',
      decimal_greater_than_zero: 'The field should be a number greater than zero',
      decimal: 'The field should be a number'
    }
  },
  shared: {
    new: "New",
    save: "Save",
    create: "Create",
    next: "Next",
    previous: "Previous",
    add: "Add",
    edit: "Edit",
    delete: "Delete",
    ok: "OK",
    done: "Done",
    cancel: 'Cancel',
    genericError: "Something went wrong",
    successfulExecution: "Success",
    stepNumber: "Step {number}",
    selectFile: "Select File",
    documentRequired: "Must provide at least one Resolution"
  },
  draft: "Draft",
  pending: "Pending",
  active: "Active",
  activatedDate: "Activated At",
  shareHolder: "Shareholder",
  shareHolders: "Shareholders",
  totalPrice: "Total Price",
  optionHolders: "Option Holders",
  optionHolder: "Option Holder",
  pool: "Share Options Pool",
  options: "Share Options",
  shares: "Shares",
  vesting: "Vesting",
  vestingDate: "Vesting Date",
  exerciseBy: "Exercise By",
  exercised: "Exercised",
  exercisePrice: "Exercise Price",
  exerciseDate: "Exercise Date",
  exercisePriceTooltip: "Price is calculated using a Weighted Average formula",
  exerciseDuration: "Exercise Duration (in months)",
  exerciseDurationTooltip: "For how long, in months, can Option Holders exercise their options after being vested",
  projectedProfit: "Projected Profit",
  projectedNetOfTax: "Projected Profit (after tax)",
  profit: "Profit",
  profitAfterTax: "Profit (after tax)",
  vested: "Vested",
  vestingStartDate: "Vesting Start Date",
  yetToVest: "Yet to Vest",
  yearsAfterTheVestingDate: "{years} years after the vesting date",
  percentage: "Percentage",
  projectedPricePrompt: "Type your price to project profit",
  typeOfShares: "Type of Shares",
  numberofShares: "Number of Shares",
  sharesIssued: "Shares Issued",
  sharePrice: "Share Price (€)",
  totalValue: "Total Value",
  type: "Type",
  total: "Total",
  remaining: "Remaining",
  status: "Status",
  approved: "Approved",
  created: "Created",
  grantPrice: "Grant Price",
  issueDate: "Issue Date",
  issued: "Issued",
  or: "or",
  name: "Name",
  className: "Class Name",
  totalOwnership: "Total Ownership",
  manage: "Manage",
  view: "View",
  all: "All",
  capitalOwnership: "Capital Ownership",
  simulateOptionsExercised: "Simulate Options Exercised",
  viewVotingShareholdersOnly: "View Voting Shareholders Only",
  simulateNewIssuance: "Simulate New Issuance",
  sharesIssuances: "Shares Issuances",
  sharesIssuance: "Shares Issuance",
  optionsIssuances: "Share Options Issuances",
  optionsIssuance: "Share Options Issuance",
  shareClass: "Share Class",
  capTableSubtitle: "View/Simulate Your Cap Table",
  viewOptions: "View Options",
  simulationWarning: "You have simulation options applied. View may not reflect actual data",
  overallOwnership: "Overall Ownership",
  shareClasses: "Share Classes",
  companyTypes: "Company Types",
  companyType: "Company Type",
  grantOptions: "Grant Options",
  vestingPaceValue: "{percent}% every {months} months",
  vestingPace: "Vesting Pace",
  shareClassRestrictions: "Share Class Rules",
  companySharePercent: "Company Shares Percent",
  stakeholder: "Stakeholder",
  stakeholders: "Stakeholders",
  directors: "Directors",
  email: "e-mail",
  phone: "Phone",
  address: "Address",
  postCode: "Post Code",
  firstName: "First Name",
  lastName: "Last Name",
  remainingOptions: "Remaining Options",
  contact: "Contact",
  live: "Live",
  pendingApproval: "Pending Approval",
  update: "Update",
  back: "Back",
  city: "City",
  country: "Country",
  addressState: "State",
  streetAddress: "Street Address",
  incorporationDate: "Incorporation Date",
  companyDistinctiveName: "Distinctive Name",
  crnNumber: "CRN Number",
  accountingStartDate: "Accounting Start Date",
  accountingEndDate: "Accounting End Date",
  taxId: "Tax ID",
  taxOffice: "Tax Office",
  amv: "AMV",
  umv: "UMV",
  expDate: "Exp. Date",
  documents: "Documents",
  shareholdersListTitle: "Shareholders",
  totalOptions: "Total Options",
  vestedOptions: "Vested Options",
  vestingOptions: "Vesting Options",
  exercisedOptions: "Exercised Options",
  expiredOptions: "Expired Options",
  poolOptionTypes: "Type",
  manualKpi: "Manual KPI",
  valuations: "Valuations",
  mimeType: "File Type",
  send: "Send",
  isBoardMember: "Board Member",
  newSharesIssuance: "New Shares Issuance",
  newBatchSharesIssuance: "New Shares Issuances Batch",
  issuanceDate: "Grant Date",
  isEmployee: "Employee",
  isFullTimeEmployee: "Full-time",
  isFullTimeEmployeeTooltip: "You can only give Options to full-time employees",
  createValuationTitle: "Add Valuation",
  createValuationSubtitle: "Submit the form for review",
  addStakeholder: "New Stakeholder",
  company: "Company",
  submit: "Submit",
  rules: "Rules",
  cancelAll: "Cancel All",
  cancelPartial: "Cancel Partial",
  cancelOptions: "Cancel Share Options",
  vestOptions: "Vest Share Options",
  vestAll: "Vest All",
  vestPartial: "Vest Partial",
  isCompany: "Legal Entity",
  TotalInvestment: "Total Investment",
  optionsModule: {
    proofOfPayment: "Payment Receipt",
    overview: "Overview",
    poolName: "Pool Name",
    poolSize: "Pool Size",
    vestingPeriod: "Vesting Period (in months)",
    vestingPeriodTooltip: "How often, in months, the specified percent of options will be vested",
    vestingPercent: "Vesting Percent per Period (%)",
    vestingPercentTooltip: "The percent of options to vest at the end of each period",
    totalVestingPeriod: "Total Vesting Period (in years)",
    totalVestingPeriodTooltip: "How long will it take, in years, for all options to be vested",
    documentInstuctions: "Select the template document in order to create new document that will request signatures from the director and the shareholder. \nSelect any other document in order to link it with the option issuance.",
    createCertificate: "Generate Share Certificate",
    navHeader: "SHARE OPTIONS",
    pagePrompt: "Create and manage your scheme",
    viewCreatePoolPrompt: "Create an Option Pool",
    addValuation: "Add Valuation",
    grantOptionsPrompt: "Grant Options",
    grant: "Grant",
    createAnOptionPool: "Create an Option Pool",
    uploadResolutionPrompt: "Upload Resolution Documents",
    shareholderResolution: "Shareholder Resolution",
    directorResolution: "Director Resolution",
    directorShareholderResolution: "Director/Shareholder Resolution",
    poolAttributes: "Pool Attributes",
    beneficiary: "Beneficiary",
    createPool: {
      restrictionsSummaryLabel: "Existing share class restrictions summary (optional)",
      noRestrictionDeclarationText: "There are no restrictions on the shares being offered or I am only issuing unapproved options"
    },
    widgets: {
      optionsInPool: "Share Options Pools",
      optionsInDraft: "Draft Share Options",
      pendingAcceptance: "Pending Acceptance Share Options",
      vested: "Vested Share Options",
      vesting: "Vesting Share Options",
      exercised: "Exercised Share Options",
      optionsIssued: "Issued Share Options"
    }
  },
  myDashboard: {
    navHeader: "DASHBOARD",
  },
  companyModule: {
    navHeader: "COMPANY",
  },
  capTable: {
    navHeader: "CAP TABLE",
  },
  documentsModule: {
    uploadDocumentTitle: "Upload a Document",
    navHeader: "DOCUMENTS",
    pagePrompt: "Create and manage your documents",
    addDocuments: "Add Documents",
    widgets: {
      allDocuments: "All Documents",
      pendingSign: "Pending Signatures",
      signedDocuments: "Signed Documents"
    }
  },
  stakeholdersModule: {
    navHeader: "STAKEHOLDERS",
    addNewStakeholder: "Add New Stakeholder",
    updateStakeholder: "Edit Stakeholder"
  },
  greekCompanyTypes: {
    ae: "S.A.",
    ike: "P.C."
  },
  adminModule: {
    navHeader: "ADMIN"
  },
  companySettingsModule: {
    navHeader: "SETTINGS",
    navHeaderNormalized: "Settings"
  },
  saveAsDraft: "Save as a draft",
  notSelected: "Not Selected",
  typeConfirmCodePrompt: "Type the code to proceed",
  confirmShareIssuanceMessage: "The share issuance will be pending for review. After the review, this action will impact your cap table! Do you want to proceed?",
  confirmOptionsIssuanceMessage: "The options issuance will be pending for review. Do you want to proceed?",
  confirmCreatePoolMessage: "The options pool will be pending for review. Do you want to proceed?",
  emailAll: "Email All",
  docType: {
    resolution: "Resolution",
    optionAgreement: "Options Agreement",
    share: "Shares",
    certificate: "Shares Certificate",
    other: "Other",
    companyPolicy: "Company Policy",
    employmentContracts: "Employment Contracts",
    sharesAgreement: "Shareholder Agreement File",
    articlesofAssociation: "Articles of Association",
  },
  confirmPoolDeleteMessage: "You are about to delete pool '{poolName}'. Delete?",
  confirmOptionsIssuanceDeleteMessage: "You are about to delete options for '{name}'. Delete?",
  confirmSharesIssuanceDeleteMessage: "You are about to delete this draft. Delete?",
  confirmSharesIssuanceRowDeleteMessage: "You are about to delete this row. Delete?",
  availableOptionsExceededException: "You have exceeded the max available options",
  invalidVestingStartDateException: "You cannot pick vesting date more than {years} years ago",
  poolNameAlreadyExistsException: "Pool name already exists",
  maxPoolLimitExceededdException: "More than {percentage}% of company shares is not allowed",
  CannotGiveOptionsToNonEmployeesException: "You cannot give options to non employess",
  vatNumber: "Tax ID Number",
  vestingProgress: "Vesting Progress",
  sendInvitation: "Send Invitation",
  revokeInvitation: "Revoke Invitation",
  resendInvitation: "Resend Invitation",
  delete: "Delete",
  confirmation: "Confirmation",
  exerciseOptions: "Exercise Share Options",
  sharesInputMessage: '{vested} available share options to exercise',
  signUp: {
    signup: {
      title: 'Sign-up',
      prompt: 'Enter your credentials',
    },
    reset: {
      title: 'Reset you password',
      prompt: 'Type a new password',
    },
    password: 'Enter a password',
    password2: 'Re-enter the password',
    submit: 'Submit'
  },
  forgotPassword: 'Forgot Password?',
  requestReset: {
    title: 'Request password reset',
    prompt: 'Enter your email',
    submit: 'Submit'
  },
  Contactus: {
    Title: 'Contact Us',
    body: 'Message',
    subject: 'Subject',
    submit: 'Submit'
  },
  optionsIssuanceDetails: "Share Options Details",
  addOne: "New Shares",
  addBatch: "New Multiple Shares"
}