export * from "./shares-issuances.service.dto";
import { api, convertTObjecToFormData } from "@/service";
import { NewSharesIssuanceDto, SharesIssuanceAnalysisDto, SharesIssuanceDraftDto, SharesIssuanceDto, UpdateSharesIssuanceDto } from "./shares-issuances.service.dto";

/* ================================================================================= */
/* GET ============================================================================= */
/* ================================================================================= */

export async function getSharesIssuancesAnalysisOfCompany(companyId: string): Promise<Array<SharesIssuanceAnalysisDto>> {
  const result = await api.get<Array<SharesIssuanceAnalysisDto>>(`/api/shares-issuances/analysis`, { companyId });
  return result.map(x => {
    x.issueDate = new Date(x.issueDate);
    return x;
  });
}

export async function getSharesIssuancesAnalysis(id: string): Promise<SharesIssuanceAnalysisDto> {
  const result = await api.get<SharesIssuanceAnalysisDto>(`/api/shares-issuances/analysis/${id}`);
  result.issueDate = new Date(result.issueDate);
  return result;
}

export async function getSharesIssuanceDraft(id: string): Promise<SharesIssuanceDraftDto> {
  const result = await api.get<SharesIssuanceDraftDto>(`/api/shares-issuances/${id}`);
  result.issueDate = new Date(result.issueDate);
  return result;
}

export async function getSharesIssuancesAnalysisOfStakeholder(stakeholderId?: string): Promise<Array<SharesIssuanceAnalysisDto>> {
  const result = await api.get<Array<SharesIssuanceAnalysisDto>>(`/api/shares-issuances/analysis`, { stakeholderId });
  return result.map(x => {
    x.issueDate = new Date(x.issueDate);
    return x;
  });
}

/* ================================================================================= */
/* POST ============================================================================= */
/* ================================================================================= */

export function createSharesIssuance(issuance: NewSharesIssuanceDto): Promise<SharesIssuanceDto> {
  return api.postForm<SharesIssuanceDto>(`/api/shares-issuances`, convertTObjecToFormData(issuance));
}

function startBatchSharesIssuanceTransaction(count: number): Promise<string> {
  return api.post<string>(`/api/shares-issuances/batch-transaction`, { count });
}

export async function createBatchSharesIssuance(issuances: Array<NewSharesIssuanceDto>, onProgress?: (uploaded: number, total: number) => void): Promise<void> {
  const transactionId = await startBatchSharesIssuanceTransaction(issuances.length);

  for (let index = 0; index < issuances.length; index++) {
    issuances[index].transactionId = transactionId;
    await createSharesIssuance(issuances[index]);
    onProgress?.(index + 1, issuances.length);
  }
}

/* ================================================================================= */
/* PATCH ============================================================================= */
/* ================================================================================= */

export function updateSharesIssuance(issuance: UpdateSharesIssuanceDto): Promise<SharesIssuanceDto> {
  return api.patchForm<SharesIssuanceDto>(`/api/shares-issuances`, convertTObjecToFormData(issuance));
}

/* ================================================================================= */
/* DELETE ============================================================================= */
/* ================================================================================= */

export function deleteSharesIssuance(id: string): Promise<void> {
  return api.delete<void>(`/api/shares-issuances/${id}`);
}