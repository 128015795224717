import { CompanyAnalysisDto, CompanyDto } from "./companies.service.dto";
import { api } from "@/service";

/* ================================================================================= */
/* GET ============================================================================= */
/* ================================================================================= */

export async function getAllCompanies(): Promise<Array<CompanyDto>> {
  const result = await api.get<Array<CompanyDto>>("/api/companies");
  return result.map( x=> { 
    x.valuation.expDate = new Date(x.valuation.expDate.toString()+"Z"); 
    x.incorporationDate = new Date(x.incorporationDate.toString()+"Z");
    x.accountingStartDate = new Date(x.accountingStartDate.toString()+"Z");
    x.accountingEndDate = new Date(x.accountingEndDate.toString()+"Z"); 
    return x 
  } );
}

export async function getCompany(companyId: string): Promise<CompanyDto> {
  const result = await api.get<CompanyDto>(`/api/companies/${companyId}`);
  result.valuation.expDate = new Date(result.valuation.expDate.toString()+"Z");
  result.incorporationDate = new Date(result.incorporationDate.toString()+"Z");
  result.accountingStartDate = new Date(result.accountingStartDate.toString()+"Z");
  result.accountingEndDate = new Date(result.accountingEndDate.toString()+"Z");
  return result;
}

export async function getCompanyAnalysis(companyId: string): Promise<CompanyAnalysisDto> {
  const result = await api.get<CompanyAnalysisDto>(`/api/companies/${companyId}/analysis`);
  result.valuation.expDate = new Date(result.valuation.expDate.toString()+"Z");
  result.incorporationDate = new Date(result.incorporationDate.toString()+"Z");
  result.accountingStartDate = new Date(result.accountingStartDate.toString()+"Z");
  result.accountingEndDate = new Date(result.accountingEndDate.toString()+"Z");
  return result;
}

/* ================================================================================== */
/* POST ============================================================================= */
/* ================================================================================== */


export async function createCompany(companyId: string, resource: CompanyDto): Promise<CompanyDto> {
  return api.post(`/api/companies/${companyId}`, resource);
}

/* ================================================================================= */
/* PUT ============================================================================= */
/* ================================================================================= */

export async function update(companyId: string, resource: CompanyDto): Promise<CompanyDto> {
  return api.put(`/api/companies/${companyId}`, resource);
}

/* ==================================================================================== */
/* DELETE ============================================================================= */
/* ==================================================================================== */

export async function remove(companyId: string): Promise<void> {
  return api.delete(`/api/companies/${companyId}`);
}


