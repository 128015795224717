import _Vue from "vue";
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, min, min_value, numeric, max_value, email, double } from 'vee-validate/dist/rules'
import { localize, configure } from 'vee-validate';
import { i18n } from "./i18n";
import el from "../lang/el";
import en from "../lang/en";


localize('el', el.$validator);
localize('en', en.$validator);

configure({

  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`$validator.messages.${values._rule_}`, values).toString();
  }
});

export function plugin(Vue: typeof _Vue): void {

  Vue.component('ValidationProvider', ValidationProvider);
  Vue.component('ValidationObserver', ValidationObserver);

  extend('required', required);
  extend('min', min);
  extend('min_value', min_value);
  extend('numeric', numeric);
  extend('max_value', max_value);
  extend('decimal', {
    validate: (value: any): boolean => {
      return /^\d*\.?\d+$/.test(value);
    }
  }
  );
  extend('vestingPercent', {
    validate: (value: any): boolean => {
      const num = parseFloat(value);
      return typeof num == "number" && !isNaN(num) && num != Infinity && num >= 1 && num <= 100
    }
  });

  extend('decimal_greater_than_zero', {
    validate: (value: any): boolean => {
      const num = parseFloat(value);
      return typeof num == "number" && !isNaN(num) && num != Infinity && num > 0
    }
  });
}
