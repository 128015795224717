import { rest } from 'msw'
import * as faker from "faker";
import { optionsPoolsData } from "./data";
import { OptionsPoolCreateDto, OptionsPoolDto, OptionsPoolsOverviewDto } from '../options-pools.service.dto';

export const optionsPoolshandlers = [

  rest.get<any, OptionsPoolsOverviewDto>("*/api/options-pools/overview", (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({
      pendingAcceptance: faker.datatype.number({ min: 0, max: 1000 }),
      optionsInDraft: faker.datatype.number({ min: 0, max: 1000 }),
      optionsInPools: faker.datatype.number({ min: 0, max: 1000 }),
      vesting: faker.datatype.number({ min: 0, max: 1000 }),
      vested: faker.datatype.number({ min: 0, max: 1000 }),
      exercised: faker.datatype.number({ min: 0, max: 1000 })
    }))
  }),

  rest.get<any, OptionsPoolDto>("*/api/options-pools/:poolId", (req, res, ctx) => {
    const { poolId } = req.params;
    const pool = optionsPoolsData.find(x => x.id == poolId)
    if (pool)
      return res(ctx.status(200), ctx.json(pool));
    else
      return res(ctx.status(404));
  }),



  rest.get<any, Array<OptionsPoolDto>>("*/api/options-pools", (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(optionsPoolsData))
  }),

  rest.post<OptionsPoolCreateDto, OptionsPoolDto>("*/api/options-pools", (req, res, ctx) => {

    return res(ctx.status(200))

    // optionsPoolsData.push({
    //   id: (optionsPoolsData.length + 1).toString(),
    //   name: req.body.name,
    //   optionsRemaining: req.body.optionsTotal,
    //   status: "Pending",
    //   optionsTotal: req.body.optionsTotal,
    //   vestingPercent: req.body.vestingPercent,
    //   rules: req.body.rules,
    //   exercisePrice: req.body.exercisePrice,
    //   shareClass: getOneRandom(sharesClassesData),
    //   directorFile: createDocumentFromFile(req.body.directorFile, "Resolution"),
    //   shareholderFile: createDocumentFromFile(req.body.shareholderFile, "Resolution"),
    //   exerciseDuration: req.body.exerciseDuration,
    //   vestingDuration: req.body.vestingDuration
    // })
  })
];

