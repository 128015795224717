import {  ValuationDto } from "../valuations.service.dto";
import { fakeIdGenerator, getManyRandom, getOneRandom } from "@/service/mocks";
import * as faker from "faker";

import { documentsData } from "@/service/documents/__mocks__/data";

export const valuationId = fakeIdGenerator();

export const valuationsData: Array<ValuationDto> = new Array(10).fill({}).map(x => ({
    id: valuationId(),
    type: getOneRandom(["options", "shares"]),
    createdDate: new Date(),
    status: getOneRandom(["pending", "active"]),
    actualMarketValue: faker.datatype.number({ min: 1000000, max: 5000000 }),
    unrestrictedMarketValue: faker.datatype.number({ min: 1000000, max: 5000000 }),
    expDate: faker.date.future(1),
    files: getManyRandom(documentsData, 3)
} as ValuationDto));