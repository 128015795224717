import { getOneById } from "@/service/mocks";
import { rest } from "msw";
import { StakeholderDto, StakeholderIncludeProperty } from "../stakeholders.service.dto";
import { stakeholdersData, stakeHolderId as stakeHolderNextId } from "./data";
import * as faker from "faker";


interface GetStakeHoldersParams {
  include?: Array<StakeholderIncludeProperty>,
  companyId?: string,
  poolId?: string
}

export const stakeHoldersHandlers = [

  rest.get<undefined, Array<StakeholderDto>, GetStakeHoldersParams>("*/api/stakeholders", (req, res, ctx) => {

    let result = stakeholdersData;
    const queryInclude = req.url.searchParams.get("include") ? req.url.searchParams.get("include")?.split(",") as Array<StakeholderIncludeProperty> : undefined;

    if (queryInclude?.includes("options-overview")) {
      result = result.map(x => {
        return {
          ...x,
          optionsOverview: {
            draft: faker.datatype.number({ min: 0, max: 10000 }),
            vested: faker.datatype.number({ min: 0, max: 10000 }),
            total: faker.datatype.number({ min: 0, max: 10000 }),
            exercised: faker.datatype.number({ min: 0, max: 10000 }),
            pending: faker.datatype.number({ min: 0, max: 10000 })
          }
        }
      })
    }

    return res(ctx.status(200), ctx.json(result));
  }),


  rest.get<StakeholderDto>("*/api/stakeholders/:stakeholderId", (req, res, ctx) => {
    const { stakeholderId } = req.params;
    const resource = getOneById(stakeholdersData, stakeholderId);
    if (resource)
      return res(ctx.status(200), ctx.json(resource.item));
    else
      return res(ctx.status(404));
  }),

  rest.put<StakeholderDto, StakeholderDto>("*/api/stakeholders/:stakeholderId", (req, res, ctx) => {
    const { stakeholderId } = req.params;
    const stakeHolder = getOneById(stakeholdersData, stakeholderId);
    if (stakeHolder.item) {
      stakeholdersData[stakeHolder.index] = req.body;
      return res(ctx.status(200), ctx.json(stakeholdersData[stakeHolder.index]));
    }
    else
      return res(ctx.status(404));
  }),

  rest.post<StakeholderDto, StakeholderDto>("*/api/stakeholders",
    (req, res, ctx) => {
      const companyId = req.url.searchParams.get("companyId")!;
      req.body.id = stakeHolderNextId();
      stakeholdersData.push(req.body);
      return res(ctx.status(200), ctx.json(req.body));
    }),

  rest.delete<StakeholderDto, StakeholderDto>("*/api/stakeholders/:stakeholderId", (req, res, ctx) => {
    const { stakeholderId } = req.params;
    const stakeHolder = getOneById(stakeholdersData, stakeholderId);
    if (stakeHolder.item) {
      stakeholdersData.splice(stakeHolder.index, 1);
      return res(ctx.status(200));
    }
    else
      return res(ctx.status(404));
  }),
];
