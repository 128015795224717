












































































import Vue, { PropType } from "vue";
import * as userService from "@/service/user/user.service";

export default Vue.extend({
  props: {
    source: {
      type: String as PropType<'Contactus' | 'reset'>,
      default: 'ContactUs'
    },
  },
  data() {
    return {
      loading: false,
      error: "",
      valid: false,
      subject: "",
      body: "",
      email: ""
    };
  },
  methods: {
    async ContactUs() {
      try {
        this.loading = true;
        this.error = "";
        await userService.sendContactUsMail({subject: this.subject,body: this.body,cc: this.email});
        this.$router.replace("/");
      } catch (error) {
        this.error = "Failed to send email";
      }
      this.loading = false;
    },
  },
});
