import { ValuationCreateDto, ValuationDto } from "./valuations.service.dto";
import { api, convertTObjecToFormData } from "@/service";

/* ================================================================================= */
/* GET ============================================================================= */
/* ================================================================================= */

export async function getValuationsOfCompany(companyId: string): Promise<Array<ValuationDto>> {
  const result = await api.get<Array<ValuationDto>>(`/api/valuations`, { companyId });
  return result.map( x=> { 
    x.createdDate = new Date(x.createdDate); 
    x.expDate = x.expDate ? new Date(x.expDate) : undefined; 
    x.activatedDate = x.activatedDate ? new Date(x.activatedDate) : undefined; 
    return x 
  });
}


/* ================================================================================== */
/* POST ============================================================================= */
/* ================================================================================== */

export async function createValuationForCompany(companyId: string, valuation: ValuationCreateDto, files: Array<File>): Promise<string> {
  
  // Step 1: Create a Valuation with no files
  const valuationResponse = await api.post<ValuationDto>(`/api/valuations`, valuation, { companyId });

  // Step 2: Attach any files in parallel and wait for them to upload
  const promises = files.map( file => attachDocumentOnValuation(valuationResponse.id, file ))
  await Promise.all(promises);

  // Step 3: Finally return ID of the new Valuation
  return valuationResponse.id;

}

export function attachDocumentOnValuation(valuationId: string, document: File): Promise<void> {
  return api.postForm(`/api/valuations/${valuationId}/documents`, convertTObjecToFormData({ file: document}));
}

