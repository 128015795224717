export * from "./share-classes.service.dto";
import { ShareClassDto } from "./share-classes.service.dto";
import { api } from "@/service"

export async function getAllShareClassesOfCompany(companyId: string): Promise<Array<ShareClassDto>> {
  return api.get(`/api/share-classes/`, { companyId });
}

export async function getShareClass(id: string): Promise<ShareClassDto> {
  return api.get(`/api/share-classes/${id}`);
}

export async function createShareClassForCompany(companyId: string, resource: ShareClassDto): Promise<ShareClassDto> {
  return api.post(`/api/share-classes`, resource, { companyId });
}

export async function updateShareClass(id: string, resource: ShareClassDto): Promise<ShareClassDto> {
  return api.put(`/api/share-classes/${id}`, resource);
}

export async function removeShareClass(id: string): Promise<void> {
  return api.delete(`/api/share-classes/${id}`);
}