import { rest } from 'msw'
import { optionsIssuanceData, optionsIssuanceId } from "./data";
import { stakeholdersData } from "@/service/stakeholders/__mocks__/data";
import { optionsPoolsData } from "@/service/options-pools/__mocks__/data";
import { OptionsIssuanceCancelDto, OptionsIssuanceCreateDto, OptionsIssuancesAnalysisDto, OptionsIssuancesDto, OptionsIssuancesOverviewDto } from '../options-issuances.service.dto';
import { getOneById, getOneRandom } from "@/service/mocks";
import * as faker from "faker";
import { createDocumentFromFile } from '@/service/documents/__mocks__/helpers';

export const optionsIssuancesHandlers = [

  rest.get<undefined, Array<OptionsIssuancesDto>>("*/api/options-issuances", (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(optionsIssuanceData));
  }),

  rest.get<undefined, Array<OptionsIssuancesAnalysisDto>>("*/api/options-issuances/analysis", (req, res, ctx) => {
    const result: Array<OptionsIssuancesAnalysisDto> = optionsIssuanceData.map(x => {
      return {
        ...x,
        stakeholder: getOneRandom(stakeholdersData),
        companyPercentage: faker.datatype.number({ min: 1, max: 100 }),
        exercisedCount: faker.datatype.number({ min: 1, max: 1000 }),
        expiredCount: faker.datatype.number({ min: 1, max: 1000 }),
        vestedCount: faker.datatype.number({ min: 1, max: 1000 }),
        vestingCount: faker.datatype.number({ min: 1, max: 1000 }),
      };
    })
    return res(ctx.status(200), ctx.json(result));
  }),

  rest.get<undefined, OptionsIssuancesOverviewDto>("*/api/options-issuances/overview", (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({
      total: {
        count: faker.datatype.number({ min: 0, max: 10000 }),
        exercisePrice: faker.datatype.number({ min: 1, max: 100 }),
        totalValue: faker.datatype.number({ min: 1, max: 10000 }),
      },
      vested: {
        count: faker.datatype.number({ min: 0, max: 10000 }),
        exercisePrice: faker.datatype.number({ min: 1, max: 100 }),
        totalValue: faker.datatype.number({ min: 1, max: 10000 }),
      },
      yetToVest: {
        count: faker.datatype.number({ min: 0, max: 10000 }),
        exercisePrice: faker.datatype.number({ min: 1, max: 100 }),
        totalValue: faker.datatype.number({ min: 1, max: 10000 }),
      },
      exercised: {
        count: faker.datatype.number({ min: 0, max: 10000 }),
        exercisePrice: faker.datatype.number({ min: 1, max: 100 }),
        totalValue: faker.datatype.number({ min: 1, max: 10000 }),
      }
    }));
  }),

  rest.post<OptionsIssuanceCreateDto, OptionsIssuancesDto>("*/api/options-issuances", (req, res, ctx) => {

    const { stakeholderId } = req.params;
    const stakeHolder = getOneById(stakeholdersData, stakeholderId);
    const pool = getOneById(optionsPoolsData, req.body.poolId);

    if (!stakeHolder.item || !pool.item)
      return res(ctx.status(400))

    const newResource: OptionsIssuancesDto = {
      id: optionsIssuanceId(),
      count: req.body.count,
      exercisedCount: 0,
      expiredCount: 0,
      vestedCount: 0,
      vestingCount: req.body.count,
      grantDate: req.body.grantDate,
      pool: pool.item,
      status: "Pending",
      vestingEndDate: faker.date.future(2),
      vestingStartDate: req.body.vestingStartDate,
      optionsAggrementFile: req.body.optionsAggrementFile ? createDocumentFromFile(req.body.optionsAggrementFile, "OptionAgreement", "pending") : undefined,
    }

    optionsIssuanceData.push(newResource);

    return res(ctx.status(200), ctx.json(newResource));
  }),

  rest.get<any, OptionsIssuancesAnalysisDto>("*/api/options-issuances/analysis/:id", (req, res, ctx) => {
    const { id } = req.params;
    const result = getOneById(optionsIssuanceData, id)
    const stakeholder = getOneRandom(stakeholdersData);

    if (result.item) {
      const analysis = {
        ...result.item,
        stakeholder: stakeholder,
        companyPercentage: faker.datatype.number({ min: 1, max: 100 }),
        exercisedCount: faker.datatype.number({ min: 1, max: 1000 }),
        expiredCount: faker.datatype.number({ min: 1, max: 1000 }),
        vestedCount: faker.datatype.number({ min: 1, max: 1000 }),
        vestingCount: faker.datatype.number({ min: 1, max: 1000 }),
      }
      return res(ctx.status(200), ctx.json(analysis));
    }
    else
      return res(ctx.status(404));
  }),

  rest.post<OptionsIssuanceCancelDto>("*/api/options-issuances/cancellation/", (req, res, ctx) => {
    return res(ctx.status(200));
  }),
];

