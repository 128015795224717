


















































































import Vue, { PropType } from "vue";
import * as auth from "@/service/user/user.service";

export default Vue.extend({
  props: {
    token: {
      required: false,
      type: String,
      default: "",
    },
    source: {
      type: String as PropType<'signup' | 'reset'>,
      default: 'signup'
    },
    email: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      error: "",
      valid: false,
      invitationRules: [
        (v: any) => !!v || "Invitation Code is required"
      ],
      passwordRules: [
        (v: any) => !!v || "Password is required",
        (v: any) => (v && v.length > 5) || "Password must be more than 5 characters",
        (v: any) => (v && /[A-Z]/.test(v) || "Password must contain 1 uppercase letter"),
        (v: any) => (v && /\d/.test(v) || "Password must contain 1 number"),
        (v: any) => (v && /[!@#$%^&*]/.test(v) || "Password must contain 1 special symbol"),
      ],
      password2Rules: [
        (v: any) => !!v || "Password is required",
        (v: any) => (v && v.length > 5) || "Password must be more than 5 characters",
        (v: any) => (v && /[A-Z]/.test(v) || "Password must contain 1 uppercase letter"),
        (v: any) => (v && /\d/.test(v) || "Password must contain 1 number"),
        (v: any) => (v && /[!@#$%^&*]/.test(v) || "Password must contain 1 special symbol"),
        (v: any) => ((this.$refs.password as any).value == v || "Password doesn't match"),
      ],
      invitation: this.token,
      password: "",
      password2: ""
    };
  },
  methods: {
    async signUp() {
      try {
        this.loading = true;
        this.error = "";
        await auth.acceptUserInvitation(this.invitation, this.password);
        this.$router.replace("/");
      } catch (error) {
        this.error = "Invalid Invitation Code";
      }
      this.loading = false;
    },
  },
});
