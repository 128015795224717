import { ShareClassDto } from "../share-classes.service.dto";
import { fakeIdGenerator } from "@/service/mocks";
import * as faker from "faker";

const shareClassId = fakeIdGenerator();

export const sharesClassesData:Array<ShareClassDto> = new Array(10).fill({}).map( x => ({
    id: shareClassId(),
    name: `Class ${faker.random.word()}`,
    canVote: faker.datatype.boolean()
}));