































































































































































import Vue from "vue";
import { state as authState, logout } from "@/service/user/user.service";
import { appStore } from "@/app.store";
import { companiesStore } from "@/service/companies/companies.store";

interface ModuleNavLink {
  text: string,
  to: string
}

interface FooterNavLink {
  href: string,
  icon: string,
  label?: string
}

export default Vue.extend({
  name: "App",
  data() {
    return {
      app: appStore.state,
      companies: companiesStore.state
    };
  },
  computed: {
    isAuthenticated(): boolean {
      return authState.user.role != "anonymous";
    },
    userInitials(): string {
      return (
        (authState.user?.firstName ?? "?").charAt(0) +
        (authState.user?.lastName ?? "?").charAt(0)
      );
    },
    fullname(): string {
      return authState.user?.firstName + " " + authState.user?.lastName;
    },
    role(): string {
      return authState.user.role;
    },
    footerLinks(): Array<FooterNavLink> {
        const links = [
          { href: 'https://www.facebook.com/Optin-IKE-104432238875883', icon: 'mdi-facebook' },
          { href: 'https://www.linkedin.com/company/optin-ike/', icon: 'mdi-linkedin' },
          { href: 'https://optin.com.gr/blog', icon: '', label: 'Blog' }
        ];
        
        if (this.app.modules.includes("guides"))
          links.push({ href: '/glossary', icon: '', label: 'Guides' });

        links.push({ href: '/contact', icon: '', label: 'Contact Us' })

        return links;
    },
    moduleNavLinks(): Array<ModuleNavLink> {
      const links = [];

      if (this.app.modules.includes("my-dashboard"))
        links.push({ text: this.$t("myDashboard.navHeader").toString(), to: "/my-dashboard" });

      if (this.app.modules.includes("company"))
        links.push({ text: this.$t("companyModule.navHeader").toString(), to: "/company" });

      if (this.app.modules.includes("options"))
        links.push({ text: this.$t("optionsModule.navHeader").toString(), to: "/options" });

      if (this.app.modules.includes("cap-table"))
        links.push({ text: this.$t("capTable.navHeader").toString(), to: "/cap-table" });

      if (this.app.modules.includes("documents"))
        links.push({ text: this.$t("documentsModule.navHeader").toString(), to: "/documents" });

      if (this.app.modules.includes("stakeholders"))
        links.push({ text: this.$t("stakeholdersModule.navHeader").toString(), to: "/stakeholders" });

      if (this.app.modules.includes("admin"))
        links.push({ text: this.$t("adminModule.navHeader").toString(), to: "/admin" });

      return links;
    },
  },
  mounted() {
    companiesStore.fetchList();
  },
  methods: {
    onCompanyChanged() {
      companiesStore.setCompany(companiesStore.state.currentId ?? "");
      window.location.replace("/");
    },
    logout() {
      logout();
      this.$router.push("/login");
    },
    companySettings() {
      this.$router.push({ path: "/settings" });
    },
  },
});
