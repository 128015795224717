import { Ability, Subject } from '@casl/ability';
import { UserDto } from './user.service';

type Actions = 'create' | 'read' | 'update' | 'delete';
type Subjects = Subject | 'Valuation' | 'OptionsPool' | 'OptionsIssuance' | 'Stakeholder' | 'Document' | 'Module' | 'ShareIssuance' | 'Company'

export type UserAbility = Ability<[Actions, Subjects]>;
export const ability = new Ability<[Actions, Subjects]>();

export function setUser(user: UserDto): void {
  switch (user.role) {
    case "manager":
      ability.update([
        { action: ['read'], subject: 'Company' },
        { action: ['read', 'create'], subject: 'Valuation' },
        { action: ['read', 'create', 'delete'], subject: 'OptionsPool' },
        { action: ['read', 'create', 'update', 'delete'], subject: 'OptionsIssuance' },
        { action: ['read', 'create', 'update', 'delete'], subject: 'Stakeholder' },
        { action: ['read', 'create', 'update'], subject: 'Document' },
        { action: ['read'], subject: 'Module', conditions: { type: { $in: ['company', 'options', 'cap-table', 'documents', 'stakeholders', 'guides'] } } },
        { action: ['read', 'create', 'update'], subject: 'ShareIssuance' },
        { action: ['read', 'create'], subject: 'StakeholderEmailNotification' },
        { action: ['read', 'create', 'delete'], subject: 'StakeholderInvitation' },
      ]);
      break;
    case "viewer":
      ability.update([
        { action: ['read'], subject: 'Company' },
        { action: ['read'], subject: 'Valuation' },
        { action: ['read'], subject: 'OptionsPool' },
        { action: ['read'], subject: 'OptionsIssuance' },
        { action: ['read'], subject: 'Stakeholder' },
        { action: ['read'], subject: 'Document' },
        { action: ['read'], subject: 'Module', conditions: { type: { $in: ['company', 'options', 'cap-table', 'documents', 'stakeholders', 'guides'] } } },
        { action: ['read'], subject: 'ShareIssuance' }
      ]);
      break;
    case "stakeholder":
      ability.update([
        { action: ['read'], subject: 'Module', conditions: { type: { $in: ['my-dashboard'] } } },
      ])
      break;
    case "backoffice":
      ability.update([
        { action: ['read'], subject: 'Company' },
        { action: ['read'], subject: 'Module', conditions: { type: { $in: ['admin', 'guides'] } } },
      ])
      break;
    default:
      ability.update([]);
  }
}