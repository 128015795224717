import * as faker from "faker";
import { fakeIdGenerator, getOneRandom } from "@/service/mocks"
import { documentsData } from "@/service/documents/__mocks__/data";
import { sharesClassesData } from "@/service/share-classes/__mocks__/data";
import { OptionsPoolDto } from "../options-pools.service.dto";

const id = fakeIdGenerator();

export const optionsPoolsData: Array<OptionsPoolDto> = new Array(100).fill({}).map(x => ({
  id: id(),
  directorFile: faker.datatype.string(),
  exercisePrice: faker.datatype.number({ min: 1, max: 100 }),
  exerciseDuration: faker.datatype.number({ min: 12, max: 48 }),
  name: "Pool " + faker.lorem.word(),
  optionsRemaining: faker.datatype.number({ min: 100, max: 200 }),
  optionsTotal: faker.datatype.number({ min: 200, max: 10000 }),
  rules: faker.lorem.paragraph(),
  shareClass: getOneRandom(sharesClassesData),
  shareholderFile: getOneRandom(documentsData),
  status: getOneRandom(["Pending", "Live"]),
  vestingDuration: faker.datatype.number({ min: 3, max: 12 }),
  vestingPercent: faker.datatype.number({ min: 1, max: 100 }),
} as OptionsPoolDto));