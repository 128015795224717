import { Store } from "@/core"
import { CompanyDto } from "./companies.service.dto";
import * as service from "./companies.service";

interface StoreData {
  list: Array<CompanyDto>
  currentId: string
  current?: CompanyDto
}

class CompaniesStore extends Store<StoreData> {
  async fetchList() {
    this.state.list = await service.getAllCompanies();
    if (!this.state.currentId && this.state.list.length) {
      this.setCompany(window.localStorage.getItem("companyId") ?? this.state.list[0].id);
    }
  }

  setCompany(id: string) {
    this.state.current = companiesStore.state.list.find(x => x.id == id);

    if (this.state.current) {
      this.state.currentId = this.state.current.id;
      window.localStorage.setItem("companyId", id);
    }
  }

  clearCompany() {
    this.state.currentId = '';
    this.state.current = undefined;
    window.localStorage.removeItem("companyId");
  }

}

export const companiesStore = new CompaniesStore({ list: [], currentId: "" });