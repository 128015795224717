import Vue from "vue";
import VueI18n from 'vue-i18n'
import el from "../lang/el";
import en from "../lang/en";

Vue.use(VueI18n);

const messages = {
    el: el,
    en: en
};

const numberFormats = {
    'en-US': {
        currency: {
            style: 'currency',
            currency: 'EUR' // Workaround to allow English with EUR
        }
    },
    'el-GR': {
        currency: {
            style: 'currency',
            currency: 'EUR'
        }
    }
}

const dateTimeFormats: VueI18n.DateTimeFormats = {
    'en-GB': {
        short: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }
    },
    'el-GR': {
        short: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        },
        long: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }
    }
}

export const i18n = new VueI18n({
    messages,
    numberFormats,
    dateTimeFormats
})

i18n.locale = 'en-GB'
