import axios from "axios";
import { startWorker as startMockedServicesApi } from "./mocks";

export async function init(baseURL: string, mocked: boolean) {

    axios.defaults.baseURL = baseURL

    if (mocked)
        return startMockedServicesApi();
    else
        Promise.resolve();
}


function resolveUrl(url: string): string {
    return url;
}

export function isApiError(error: any): error is ApiError {
    return ('type' in error && 'reason' in error);
}

export function isApiErrorOf<T extends ApiError>(error: any, type: T["type"]): error is T {
    return ('type' in error && 'reason' in error && error.type == type);
}

export interface ApiError {
    type: string;
    reason: string;
}

export const api = {
    delete: del,
    get: get,
    put: put,
    post: post,
    patch: patch,
    patchForm: patchForm,
    postForm: postForm
}

function getError(error: any): any {
    if (isApiError(error)) {
        return error
    }
    else {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
            return error.response.data;
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message);
        }
        return error;
    }
}

async function get<T>(url: string, params?: any): Promise<T> {
    try {
        const result = await axios.get(resolveUrl(url), { params });
        return result.data as T;
    }
    catch (error) {
        throw getError(error);
    }
}

async function post<T>(url: string, data?: any, params?: any): Promise<T> {
    try {
        const result = await axios.post(resolveUrl(url), data, { params });
        return result.data as T;
    }
    catch (error) {
        throw getError(error);
    }
}

async function postForm<T>(url: string, form: FormData, params?: any): Promise<T> {
    try {
        const result = await axios.post(resolveUrl(url), form, {
            params, headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return result.data as T;
    }
    catch (error) {
        throw getError(error);
    }
}

async function patch<T>(url: string, data?: any, params?: any): Promise<T> {
    try {
        const result = await axios.patch(resolveUrl(url), data, { params });
        return result.data as T;
    }
    catch (error) {
        throw getError(error);
    }
}

async function patchForm<T>(url: string, form?: FormData, params?: any): Promise<T> {
    try {
        const result = await axios.patch(resolveUrl(url), form, {
            params, headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return result.data as T;
    }
    catch (error) {
        throw getError(error);
    }
}

async function del<T>(url: string, params?: any): Promise<T> {
    try {
        const result = await axios.delete(resolveUrl(url), { params });
        return result.data as T;
    }
    catch (error) {
        throw getError(error);
    }
}


async function put<T>(url: string, data?: any, params?: any): Promise<T> {
    try {
        const result = await axios.put(resolveUrl(url), data, { params });
        return result.data as T;
    }
    catch (error) {
        throw getError(error);
    }
}



export function convertTObjecToFormData(obj: any): FormData {
    const formData = new FormData();

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const element = obj[key];
            if (typeof element != "undefined") {
                if (typeof element == "string") {
                    formData.append(key, element);
                }
                else if (element instanceof File) {
                    formData.append(key, element);
                }
                else if (element instanceof Date) {
                    formData.append(key, element.toISOString());
                }
                else if (Array.isArray(element)) {
                    element.forEach((el, index) => {
                        formData.append(key + "-" + index, el);
                    })
                }
                else {
                    formData.append(key, element.toString());
                }
            }
        }
    }

    return formData;

}

