import * as faker from "faker";
import { fakeIdGenerator, getOneRandom } from "@/service/mocks"
import { DocumentDto } from "@/service/documents/documents.service.dto";

export const documentId = fakeIdGenerator();

export const documentsData: Array<DocumentDto> = new Array(100).fill({}).map(x => ({
  id: documentId(),
  mimeType: "application/pdf",
  name: `${faker.lorem.words(2)}.pdf`,
  type: getOneRandom(["Resolution", "OptionAgreement", "Share", "Certificate", "Other"]),
  createdDate: faker.date.past()
} as DocumentDto));