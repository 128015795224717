import * as faker from "faker";
import { fakeIdGenerator, getOneRandom } from "@/service/mocks";
import { SharesIssuanceDto, SharesIssuanceStatus } from "../shares-issuances.service.dto";
import { sharesClassesData } from "@/service/share-classes/__mocks__/data";
import { documentsData } from "@/service/documents/__mocks__/data";

export const sharesIssuancesId = fakeIdGenerator();

export const sharesIssuancesData: Array<SharesIssuanceDto> = new Array(100).fill({}).map(x => ({
  id: sharesIssuancesId(),
  count: faker.datatype.number({ min: 1, max: 10000 }),
  grantPrice: faker.datatype.number({ min: 1, max: 10000 }),
  issueDate: faker.date.past(),
  shareClass: getOneRandom(sharesClassesData),
  shareCertificateFile: getOneRandom(documentsData),
  shareholderAgreementFile: getOneRandom(documentsData),
  status: getOneRandom<SharesIssuanceStatus>(["drafted", "pending", "issued"])
} as SharesIssuanceDto));