import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import * as auth from "@/service/user/user.service";
import Login from "../views/Login.vue";
import SignUp from "../views/SignUp.vue";
import ContactUs from "../views/contactus/ContactUs.vue";
import Glossary from "../views/Glossary/Glossary.vue";
import PageNotFound from "../views/PageNotFound.vue";
import RequestResetPassword from "../views/RequestResetPassword.vue";
import Forbidden from "../views/Forbidden.vue";
import { companiesStore } from "@/service/companies/companies.store";
import { subject } from "@casl/ability";
import { ability } from "@/service/user/ability";
import { appStore } from "@/app.store";

Vue.use(VueRouter);


const routes: Array<RouteConfig> = [
  { path: "/login", name: "login", component: Login, props: true },
  {
    path: "/sign-up", name: "sign-up", component: SignUp,
    props: route => ({ token: route.query.token, email: route.query.email })
  },
  {
    path: "/contact", name: "contact", component: ContactUs
  },
  {
    path: "/glossary", name: "glossary", component: Glossary
  },
  {
    path: "/reset-password", name: "reset-password", component: SignUp,
    props: route => ({ token: route.query.token, email: route.query.email, source: 'reset' })
  },
  {
    path: "/request-reset-password", name: "request-reset-password", component: RequestResetPassword
  },
  { path: "*", component: PageNotFound },
  { path: "/forbidden", name: "forbidden", component: Forbidden },
  {
    path: "/",
    beforeEnter(to, from, next) {
      if (appStore.state.modules.length > 0)
        next({ name: appStore.state.modules[0] });
      else
        next();
    },
    component: () => import("../views/Home.vue")
  },
  {
    path: "/options",
    name: 'options',
    meta: { allow: [{ action: 'read', subject: subject("Module", { type: "options" }) }] },
    component: () => import(/* webpackChunkName: "options" */ "../views/options/ViewOptions.vue"),
    children: [
      { path: "", component: () => import(/* webpackChunkName: "options" */ "../views/options/SubViewOverview.vue") },
      { path: "pools", component: () => import(/* webpackChunkName: "options" */ "../views/options/SubViewPoolsList.vue") },
      { path: "drafted", component: () => import(/* webpackChunkName: "options" */ "../views/options/SubViewOptionsInDraft.vue") },
      { path: "pending", component: () => import(/* webpackChunkName: "options" */ "../views/options/SubViewOptionsPendingAcceptance.vue") },
      { path: "vesting", component: () => import(/* webpackChunkName: "options" */ "../views/options/SubViewOptionsVesting.vue") },
      { path: "vested", component: () => import(/* webpackChunkName: "options" */ "../views/options/SubViewOptionsVested.vue") },
      { path: "exercised", component: () => import(/* webpackChunkName: "options" */ "../views/options/SubViewOptionsExercised.vue") },
      { path: "issued", component: () => import(/* webpackChunkName: "options" */ "../views/options/SubViewOptionsIssued.vue") }
    ]
  },
  {
    path: "/options/create",
    meta: { allow: [{ action: 'create', subject: "OptionsPool" }] },
    component: () => import(/* webpackChunkName: "options" */ "../views/options/ViewCreatePool.vue"),
  },
  {
    name: "create-options-issuance",
    path: "/options/grant-options",
    meta: { allow: [{ action: 'create', subject: "OptionsIssuance" }] },
    component: () => import(/* webpackChunkName: "options" */ "../views/options/ViewGrantOptions.vue"),
    props: route => ({ poolId: route.query.poolId, id: route.query.id })
  },
  {
    path: "/options/pool",
    meta: { allow: [{ action: 'read', subject: "OptionsPool" }] },
    component: () => import(/* webpackChunkName: "options" */ "../views/options/ViewPool.vue"),
    props: route => ({ id: route.query.id })
  },
  {
    name: "view-options-issuance",
    path: "/options/view/:id",
    meta: { allow: [{ action: 'read', subject: "OptionsIssuance" }] },
    component: () => import(/* webpackChunkName: "options" */ "../views/options/ViewOptionsIssuance.vue"),
    props: route => ({ id: route.params.id })
  },
  {
    name: "view-options-issuance-details",
    path: "/options/view/:id/details",
    meta: { allow: [{ action: 'read', subject: "OptionsIssuance" }] },
    component: () => import(/* webpackChunkName: "options" */ "../views/options/ViewOptionsIssuanceDetails.vue"),
    props: route => ({ id: route.params.id })
  },
  {
    name: "cancel-options-issuance",
    path: "/options/cancel/:id",
    meta: { allow: [{ action: 'update', subject: "OptionsIssuance" }] },
    component: () => import(/* webpackChunkName: "options" */ "../views/options/CancelOptionsIssuance.vue"),
    props: route => ({ id: route.params.id })
  }, {
    name: "vest-options-issuance",
    path: "/options/vest/:id",
    meta: { allow: [{ action: 'update', subject: "OptionsIssuance" }] },
    component: () => import(/* webpackChunkName: "options" */ "../views/options/VestOptionsIssuance.vue"),
    props: route => ({ id: route.params.id })
  },
  {
    name: "exercise-options-issuance",
    path: "/shares/exercise-options-issuance/:id",
    meta: { allow: [{ action: 'create', subject: "OptionsIssuance" }, { action: 'update', subject: "OptionsIssuance" }] },
    component: () => import(/* webpackChunkName: "options" */ "../views/options/ViewExerciseOptions.vue"),
    props: route => ({ id: route.params.id })
  },
  {
    name: "view-options-issuance-list-of-option-holder",
    path: "/option-holder/:id/options",
    meta: { allow: [{ action: 'read', subject: "OptionsIssuance" }] },
    component: () => import(/* webpackChunkName: "options" */ "../views/options/ViewOptionsIssuancesListOfOptionHolder.vue"),
    props: route => ({ optionHolderId: route.params.id })
  },
  {
    path: "/my-dashboard",
    name: "my-dashboard",
    meta: { allow: [{ action: 'read', subject: subject("Module", { type: "my-dashboard" }) }] },
    component: () => import(/* webpackChunkName: "my-dashboard" */ "../views/my-dashboard/MyDashboard.vue")
  },
  {
    path: "/cap-table",
    name: "cap-table",
    meta: { allow: [{ action: 'read', subject: subject("Module", { type: "cap-table" }) }] },
    component: () => import(/* webpackChunkName: "cap-table" */ "../views/cap-table/CapTable.vue")
  },
  {
    path: "/stakeholders",
    name: "stakeholders",
    meta: { allow: [{ action: 'read', subject: subject("Module", { type: "stakeholders" }) }] },
    component: () => import(/* webpackChunkName: "stakeholders" */ "../views/stakeholders/ViewStakeholders.vue"),
    props: route => ({ tab: route.query.tab })
  },
  {
    path: "/stakeholders/:id",
    meta: { allow: [{ action: 'read', subject: "Stakeholder" }] },
    component: () => import(/* webpackChunkName: "stakeholders" */ "../views/stakeholders/ViewStakeholder.vue"),
    props: route => ({ id: route.params.id })
  },
  {
    path: "/create-stakeholder",
    meta: { allow: [{ action: 'create', subject: "Stakeholder" }] },
    component: () => import(/* webpackChunkName: "stakeholders" */ "../views/stakeholders/ViewStakeholder.vue"),
    props: route => ({ id: 0 })
  },
  {
    path: "/documents",
    name: "documents",
    meta: { allow: [{ action: 'read', subject: subject("Module", { type: "documents" }) }] },
    component: () => import(/* webpackChunkName: "documents" */ "../views/documents/ViewDocuments.vue"),
    children: [
      { path: "", component: () => import(/* webpackChunkName: "documents" */ "../views/documents/DocumentsSubViewOverview.vue") },
      { path: "All", component: () => import(/* webpackChunkName: "documents" */ "../views/documents/SubViewAllDocuments.vue") },
      { path: "Pending", component: () => import(/* webpackChunkName: "documents" */ "../views/documents/SubViewPendingSignDocuments.vue") },
      { path: "Signed", component: () => import(/* webpackChunkName: "documents" */ "../views/documents/SubViewSignedDocuments.vue") },
      { path: "Create", component: () => import(/* webpackChunkName: "documents" */ "../views/documents/ViewUploadDocument.vue") }
    ],
    props: route => {
      if (typeof route.query.filter == "string") {
        const [entity, id] = route.query.filter.split(',');
        return {
          filter: {
            entity,
            id
          }
        }
      }
    }
  },
  {
    name: "view-document",
    path: "/documents/:id",
    meta: { allow: [{ action: 'read', subject: "Document" }] },
    component: () => import(/* webpackChunkName: "documents" */ "../views/documents/ViewDocument.vue"),
    props: route => ({ id: route.params.id })
  },
  {
    path: "/company",
    name: "company",
    meta: { allow: [{ action: 'read', subject: subject("Module", { type: "company" }) }] },
    component: () => import(/* webpackChunkName: "company" */ "../views/company/ViewCompany.vue")
  },
  {
    path: "/company/valuations",
    meta: { allow: [{ action: 'read', subject: "Valuation" }] },
    component: () => import(/* webpackChunkName: "company" */ "../views/company/ViewValuations.vue")
  },
  {
    path: "/company/valuations/create",
    meta: { allow: [{ action: 'create', subject: "Valuation" }] },
    component: () => import(/* webpackChunkName: "company" */ "../views/company/ViewCreateValuation.vue")
  },
  {
    path: "/shares/shares-issuances",
    meta: { allow: [{ action: 'read', subject: "ShareIssuance" }] },
    component: () => import(/* webpackChunkName: "shares" */ "../views/shares/ViewShareIssuancesList.vue")
  },
  {
    name: "create-shares-issuance",
    path: "/shares/create-shares-issuance",
    meta: { allow: [{ action: 'create', subject: "ShareIssuance" }, { action: 'update', subject: "ShareIssuance" }] },
    component: () => import(/* webpackChunkName: "shares" */ "../views/shares/CreateShareIssuance.vue"),
  },
  {
    name: "create-batch-shares-issuance",
    path: "/shares/create-batch-shares-issuance",
    meta: { allow: [{ action: 'create', subject: "ShareIssuance" }, { action: 'update', subject: "ShareIssuance" }] },
    component: () => import(/* webpackChunkName: "shares" */ "../views/shares/CreateBatchShareIssuances.vue"),
  },
  {
    name: "edit-shares-issuance",
    path: "/shares/shares-issuance/:id",
    meta: { allow: [{ action: 'create', subject: "ShareIssuance" }, { action: 'update', subject: "ShareIssuance" }] },
    component: () => import(/* webpackChunkName: "shares" */ "../views/shares/ViewEditShareIssuance.vue"),
    props: route => ({ id: route.params.id })
  },
  {
    name: "view-shares-issuance",
    path: "/shares/view/:id",
    meta: { allow: [{ action: 'read', subject: "ShareIssuance" }] },
    component: () => import(/* webpackChunkName: "shares" */ "../views/shares/ViewShareIssuance.vue"),
    props: route => ({ id: route.params.id })
  },
  {
    path: "/settings",
    meta: { allow: [{ action: 'read', subject: "Company" }] },
    component: () => import(/* webpackChunkName: "company" */ "../views/company/ViewCompanySettings.vue")
  },
  {
    path: "/admin",
    name: "admin",
    meta: { allow: [{ action: 'read', subject: subject("Module", { type: "admin" }) }] },
    component: () => import(/* webpackChunkName: "admin" */ "../views/admin/ViewAdmin.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (auth.state.user.role != "anonymous" && !companiesStore.state.currentId) {

    const unwatch = companiesStore.$watch(() => companiesStore.state.currentId, () => {
      unwatch();
      next();
    });
  }
  else {
    next();
  }
})

// Check User's abitiliies
router.beforeEach((to, from, next) => {
  let allowNavigation = true;

  if (to.matched[0].meta.allow) {
    const allowRules: Array<any> = to.matched[0].meta.allow;
    allowNavigation = allowRules.every(rule => {
      return ability.can(rule.action, rule.subject, rule.fields)
    });
  }

  next(allowNavigation ? undefined : { name: 'forbidden' })
});

export default router;

