export * from "./options-pools.service.dto";
import { api, convertTObjecToFormData } from "@/service";
import { OptionsPoolCreateDto, OptionsPoolDto, OptionsPoolsOverviewDto } from "./options-pools.service.dto";

/* ================================================================================= */
/* GET ============================================================================= */
/* ================================================================================= */

export async function getOnePool (id: string): Promise<OptionsPoolDto> {
  return api.get(`/api/options-pools/${id}`);
}

export async function getPoolsOverviewOfCompany (companyId: string): Promise<OptionsPoolsOverviewDto> {
  return api.get(`/api/options-pools/overview`, { companyId });
}

export async function getPoolsOfCompany (companyId: string): Promise<Array<OptionsPoolDto>> {
  return api.get(`/api/options-pools`, { companyId });
}

/* ================================================================================= */
/* POST ============================================================================ */
/* ================================================================================= */

export function createOptionsPoolOfCompany (companyId: string, pool: OptionsPoolCreateDto): Promise<OptionsPoolDto> {
  return api.postForm(`/api/options-pools`, convertTObjecToFormData(pool), { companyId });
}

/* ================================================================================= */
/* DELETE ============================================================================ */
/* ================================================================================= */

export function deletePool (poolId: string,): Promise<void> {
  return api.delete(`/api/options-pools/${poolId}`,);
}