import vuetify from 'vuetify/src/locale/el'
export default {
    $vuetify: vuetify,
    app:{
        logout: "Αποσύνδεση",
        
    },
    $validator:{
        messages: {
            required: 'Το πεδίο είναι υποχρεωτικό',
            min: 'Το πεδίο θέλει το ελάχιστο {length} χαρακτήρες',
            max: 'Το πεδίο θέλει το μέγιστο {length} χαρακτήρες'
          }
    },
    shared: {
        new: "Νέο",
        save: "Αποθήκευση",
        add: "Προσθήκη",
        edit: "Επεξεργασία",
        delete: "Διαγραφή",
        ok: "Εντάξει",
        cancel: 'Άκυρο',
        genericError: "Κάτι δεν πήγε κάλα. Ξαναδοκιμάστε.",
        successfulExecution: "Επιτυχής εκτέλεση"
    },
    stocks: {
        navHeader: "ΜΕΤΟΧΕΣ",
        pagePrompt: "Δημιουργήστε και διαχειριστείτε μετοχές",
        addValuation: "Add Valuation",
        createAnOptionPool:"Create an Option Pool",
        widgets: {
            amv: "AMV",
            umv: "UMV",
            expDate: "Exp. Date",
            optionsInPool: "Options in Pools",
            optionsInDraft: "Options in Draft",
            pendingAcceptance: "Pending Acceptance",
            readyToExercise: "Ready to Exercise",
            vesting: "Vesting",
        }
    },
    greekCompanyTypes :{
        ae : "Α.Ε.",
        ike: "Ι.Κ.Ε."
      },
    myDashboard: {
        navHeader: "DASHBOARD",
    },
    company: {
        navHeader: "COMPANY",
    },
    capTable: {
        navHeader: "CAP TABLE",
    },
    documents: {
        navHeader: "DOCUMENTS",
    }
}