export * from "./stakeholders.service.dto";
import { api } from "@/service";
import { StakeholderDto, StakeholderIncludeProperty } from "./stakeholders.service.dto";

export type StakeholderType = "any" | "shareHolder" | "optionsHolder" | "director";

/* ================================================================================== */
/* GET ============================================================================== */
/* ================================================================================== */

export async function getStakeholdersOfCompany(companyId: string, include?: Array<StakeholderIncludeProperty>, type?: StakeholderType): Promise<Array<StakeholderDto>> {
  return api.get(`/api/stakeholders`, { companyId, include: include?.join(","), type });
}

export async function getStakeholdersOfPool(poolId: string, include?: Array<StakeholderIncludeProperty>): Promise<Array<StakeholderDto>> {
  return await api.get(`/api/stakeholders`, { poolId, include: include?.join(",") });
}

export async function getStakeholder(id: string): Promise<Required<StakeholderDto>> {
  return await api.get(`/api/stakeholders/${id}`);
}

/* ================================================================================== */
/* CREATE =========================================================================== */
/* ================================================================================== */


export async function createStakeholder(companyId: string, stakeholder: StakeholderDto): Promise<StakeholderDto> {
  return await api.post(`/api/stakeholders`, stakeholder, { companyId });
}

export async function createEmailForAllStakeholders(companyId: string): Promise<void> {
  return await api.post(`/api/stakeholders/email`, undefined, { companyId });
}

/* ================================================================================== */
/* UPDATE =========================================================================== */
/* ================================================================================== */

export async function updateStakeholder(stakeholder: StakeholderDto): Promise<void> {
  return await api.put(`/api/stakeholders/${stakeholder.id}`, stakeholder);
}

/* ================================================================================== */
/* DELETE =========================================================================== */
/* ================================================================================== */

export async function deleteStakeholder(id: string): Promise<void> {
  return await api.delete(`/api/stakeholders/${id}`);
}