export * from "./user.service.dto";
import Vue from "vue";
import axios from "axios";
import { api, convertTObjecToFormData } from "@/service"
import { Invitation, InvitationCreateDto, InvitationPatchDto, LoginResponse, ResetPasswordCreateDto, UserDto, MailRequestDto } from "./user.service.dto";
import VueRouter from "vue-router";
import { companiesStore } from "@/service/companies/companies.store";
import { appStore } from "@/app.store";
import { setUser as setAbilityUser } from "@/service/user/ability"

export const anonymous: UserDto = {
  firstName: "Anonymous",
  lastName: "User",
  id: "",
  role: "anonymous"
}

export const state = Vue.observable({
  accessToken: null as string | null,
  refreshToken: null as string | null,
  user: anonymous as UserDto
});

export async function setup(router: VueRouter): Promise<void> {

  state.refreshToken = window.localStorage.getItem("refreshToken");
  state.accessToken = window.localStorage.getItem("accessToken");

  // Do something before request is sent
  axios.interceptors.request.use(
    function (config) {
      const token = state.accessToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const originalRequest = error.config;
      if (
        error.response.status === 401 &&
        originalRequest.url.includes("api/auth/refresh-token")
      ) {
        logout();
        router.push("/login");
        return Promise.reject(error);
      } else if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await refreshAccessToken();
        return axios(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  try {
    const user = await currentUserApi();
    state.user = user;
    setAbilityUser(state.user);
    appStore.setUser(state.user);


  } catch (error) {
    state.user = anonymous;
    setAbilityUser(state.user);
    appStore.setUser(state.user);

  }
}

export async function login(
  username: string,
  password: string
): Promise<boolean> {
  try {
    const loginSession = await loginApi(username, password);
    await onAuthenticated(loginSession);
    return true;
  } catch (error) {
    return false;
  }
}

export async function refreshAccessToken(): Promise<boolean> {
  try {
    if (state.refreshToken) {
      const loginSession = await refreshAccessTokenApi(state.refreshToken);
      await onAuthenticated(loginSession);
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export function logout(): void {
  state.user = anonymous;
  setAbilityUser(state.user);
  appStore.setUser(state.user);
  companiesStore.clearCompany();
  state.refreshToken = "";
  state.accessToken = "";
  window.localStorage.setItem("refreshToken", state.refreshToken);
  window.localStorage.setItem("accessToken", state.accessToken);
}

async function onAuthenticated(session: LoginResponse) {
  state.user = session.user;
  setAbilityUser(state.user);
  appStore.setUser(state.user);
  state.refreshToken = session.refreshToken.token;
  state.accessToken = session.accessToken.token;
  window.localStorage.setItem("refreshToken", state.refreshToken);
  window.localStorage.setItem("accessToken", state.accessToken);

  await companiesStore.fetchList()
}

async function loginApi(
  username: string,
  password: string
): Promise<LoginResponse> {
  return api.post("/api/auth/login", { username, password });
}

async function refreshAccessTokenApi(
  refreshToken: string
): Promise<LoginResponse> {
  return api.post("/api/auth/refresh-token", { refreshToken })
}

async function currentUserApi(): Promise<UserDto> {
  return api.get("/api/auth/user");
}

export async function sendUserInvitation(invitation: InvitationCreateDto): Promise<Invitation> {
  return await api.post(`/api/auth/invitations`, invitation);
}

export async function sendContactUsMail(mailRequest: MailRequestDto): Promise<Invitation> {
  return await api.postForm(`/api/email/Send`, convertTObjecToFormData(mailRequest));
}

export async function sendResetPasswordNotification(resetDto: ResetPasswordCreateDto): Promise<void> {
  return await api.post(`/api/auth/reset`, resetDto);
}

export async function revokeUserInvitation(id: string): Promise<void> {
  return await api.delete(`/api/auth/invitations/${id}`);
}

export async function acceptUserInvitation(id: string, password: string): Promise<void> {
  return await api.patch(`/api/auth/invitations/${id}`, { password: password } as InvitationPatchDto);
}
