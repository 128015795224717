import Vue, { WatchOptions } from 'vue'

export class Store<TState extends Record<string, any>> {

    public state!: TState;
    private vm: Vue;
    
    constructor(state: TState) {
        this.vm = new Vue();
        this.state = Vue.observable(state);
    }

    /**
     * Watches for changes for the specified property. See docs of Vue.js $watch() method for more
     */
    $watch<T>(expOrFn: () => T, callback: (n: T, o: T) => void, options?: WatchOptions): (() => void) {
        return this.vm.$watch<T>(expOrFn, callback, options);
    }

    /**
     * Updates the existing state with a "fresh" one. Use this when you need to 
     * replace all the original data. Useful when you get "fresh" data from another
     * service call and need to update the store
     */
    public updateAll(newState: TState): void {
        for (const key in newState) {
            if (!(key in this.state)) {
                Vue.set(this.state, key, newState[key])
            }
            else {
                this.state[key] = newState[key];
            }
        }
    }

}