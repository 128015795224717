import { rest } from 'msw'
import { sharesClassesData } from "./data";
import { ShareClassDto } from '../share-classes.service.dto';

export const shareClassesHandlers = [

  rest.get<any, Array<ShareClassDto>>("*/api/share-classes/", (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(sharesClassesData));
  })

];

