export * from "./options-issuances.service.dto";
import { api, convertTObjecToFormData } from "@/service"
import { OptionsIssuancesOverviewDto, OptionsIssuanceCreateDto, OptionsIssuancesAnalysisDto, OptionsIssuancesDto, OptionsIssuanceStatus, OptionsIssuanceCancelDto, OptionsIssuanceDraftDto, OptionsIssuanceExerciseDto, OptionsIssuanceDetailsDto, OptionsIssuanceVestDto } from "./options-issuances.service.dto";

/* ================================================================================== */
/* GET ============================================================================= */
/* ================================================================================== */

export async function getOptionsIssuancesOfCompany(companyId: string, optionStatus?: OptionsIssuanceStatus): Promise<Array<OptionsIssuancesDto>> {
  let result = await api.get<Array<OptionsIssuancesDto>>(`/api/options-issuances`, { companyId });

  if (optionStatus)
    result = result.filter(x => x.status == optionStatus);

  return result;
}

export async function getOptionsIssuanceAnalysis(id: string): Promise<OptionsIssuancesAnalysisDto> {
  const result = await api.get<OptionsIssuancesAnalysisDto>(`/api/options-issuances/analysis/${id}`);

  result.grantDate = new Date(result.grantDate);
  result.vestingStartDate = new Date(result.vestingStartDate);
  result.vestingEndDate = new Date(result.vestingEndDate);

  return result;
}

export async function getOptionsIssuanceDetails(id: string): Promise<OptionsIssuanceDetailsDto> {
  const result = await api.get<OptionsIssuanceDetailsDto>(`/api/options-issuances/${id}/details`);
  return result;
}

export async function getOptionsIssuancesAnalysisOfCompany(companyId: string): Promise<Array<OptionsIssuancesAnalysisDto>> {
  const result = await api.get<Array<OptionsIssuancesAnalysisDto>>(`/api/options-issuances/analysis`, { companyId });
  return result.map(x => {
    x.grantDate = new Date(x.grantDate);
    x.vestingStartDate = new Date(x.vestingStartDate);
    x.vestingEndDate = new Date(x.vestingEndDate);
    return x;
  });
}

export async function getOptionsIssuancesAnalysisOfStakeholder(stakeholderId?: string, optionStatus?: OptionsIssuanceStatus): Promise<Array<OptionsIssuancesAnalysisDto>> {
  let result = await api.get<Array<OptionsIssuancesAnalysisDto>>(`/api/options-issuances/analysis`, { stakeholderId });

  if (optionStatus)
    result = result.filter(x => x.status == optionStatus);

  return result.map(x => {
    x.grantDate = new Date(x.grantDate);
    x.vestingStartDate = new Date(x.vestingStartDate);
    x.vestingEndDate = new Date(x.vestingEndDate);
    return x;
  });
}

export async function getOptionsIssuancesOverviewOfStakeholder(stakeholderId?: string): Promise<OptionsIssuancesOverviewDto> {
  return api.get(`/api/options-issuances/overview`, { stakeholderId });
}

/* ================================================================================== */
/* POST ============================================================================= */
/* ================================================================================== */

/**
 * @throws AvailableOptionsExceededException
 */
export function createOptionsIssuance(optionsIssuance: OptionsIssuanceCreateDto): Promise<OptionsIssuancesDto> {
  return api.post<OptionsIssuancesDto>(`/api/options-issuances`, optionsIssuance);
}

export function createOptionsIssuanceDraft(optionsIssuance: OptionsIssuanceDraftDto): Promise<OptionsIssuancesDto> {
  return api.post<OptionsIssuancesDto>(`/api/options-issuances`, optionsIssuance);
}

export function cancelOptionsIssuance(optionsIssuance: OptionsIssuanceCancelDto): Promise<void> {
  return api.postForm<void>(`/api/options-issuances/cancellation`, convertTObjecToFormData(optionsIssuance));
}

export function vestOptionsIssuance(optionsIssuance: OptionsIssuanceVestDto): Promise<void> {
  return api.postForm<void>(`/api/options-issuances/manualVest`, convertTObjecToFormData(optionsIssuance));
}

export function exerciseOptionsIssuance(optionsIssuance: OptionsIssuanceExerciseDto): Promise<void> {
  return api.postForm<void>(`/api/options-issuances/exercise`, convertTObjecToFormData(optionsIssuance));
}

/* ================================================================================== */
/* PATCH ============================================================================= */
/* ================================================================================== */

export function updateOptionsIssuance(optionsIssuance: OptionsIssuanceCreateDto): Promise<OptionsIssuancesDto> {
  return api.patch<OptionsIssuancesDto>(`/api/options-issuances`, optionsIssuance);
}

export function updateOptionsIssuanceDraft(optionsIssuance: OptionsIssuanceDraftDto): Promise<OptionsIssuancesDto> {
  return api.patch<OptionsIssuancesDto>(`/api/options-issuances`, optionsIssuance);
}

/* ================================================================================== */
/* DELETE ============================================================================= */
/* ================================================================================== */

export function deleteOptionsIssuanceDraft(id: string): Promise<void> {
  return api.delete<void>(`/api/options-issuances/${id}`);
}



