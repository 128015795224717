export * from "./documents.service.dto";
import { api, convertTObjecToFormData } from "@/service"
import { DocumentDto, DocumentsAnalysisDto, DocumentStatus } from "./documents.service.dto";

/* ================================================================================== */
/* GET ============================================================================= */
/* ================================================================================== */

export type Entity = "company" | "valuation";
export interface Filter {
  entity: Entity
  id: string
}

export async function getDocumentsOf(filter: Filter, documentType?: DocumentType): Promise<Array<DocumentDto>> {
  const filterParam = filter ? `${filter.entity},${filter.id}` : undefined;
  let result = await api.get<Array<DocumentDto>>(`/api/documents`, { filter: filterParam});
  result.forEach(x => x.createdDate = new Date(x.createdDate))

  if (documentType)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    result = result.filter(x => x.type == documentType);

  return result;
}

export async function getDocument(id: string): Promise<DocumentDto> {
  const result = await api.get<DocumentDto>(`/api/documents/${id}`);
  result.createdDate = new Date(result.createdDate);
  return result;
}

export async function getDocumentsAnalysisOf(filter: Filter): Promise<DocumentsAnalysisDto> {
  const filterParam = filter ? `${filter.entity},${filter.id}` : undefined;
  return await api.get<DocumentsAnalysisDto>(`/api/documents/analysis`, { filter: filterParam });
}

export async function uploadForCompany(companyId: string, documentType: string, document?: File): Promise<string> {
  
  // Step 1: Create a Valuation with no files
  const documentResponse = await api.postForm<DocumentDto>(`/api/documents/UploadDocument`, convertTObjecToFormData( {companyFile: document, companyId: companyId, docType:documentType}));

  // Step 2: Attach any files in parallel and wait for them to upload

  // Step 3: Finally return ID of the new Valuation
  return documentResponse.id??"";

}