import * as faker from "faker";
import { fakeIdGenerator, getOneRandom } from "@/service/mocks"
import { OptionsIssuancesDto } from "../options-issuances.service.dto";
import { optionsPoolsData } from "@/service/options-pools/__mocks__/data";
import { documentsData } from "@/service/documents/__mocks__/data";

export const optionsIssuanceId = fakeIdGenerator();

export const optionsIssuanceData: Array<OptionsIssuancesDto> = new Array(100).fill({}).map(x => ({
  id: optionsIssuanceId(),
  count: faker.datatype.number({ min: 1, max: 10000 }),
  exercisedCount: faker.datatype.number({ min: 1, max: 10000 }),
  expiredCount: faker.datatype.number({ min: 1, max: 10000 }),
  vestedCount: faker.datatype.number({ min: 1, max: 10000 }),
  vestingCount: faker.datatype.number({ min: 1, max: 10000 }),  
  grantDate: faker.date.past(),
  optionsAggreementFile: getOneRandom(documentsData),
  status: getOneRandom(["Drafted", "Pending", "Vesting", "Vested", "Exercised"]),
  vestingEndDate: faker.date.future(2),
  vestingStartDate: faker.date.past(),
  pool: getOneRandom(optionsPoolsData)
} as OptionsIssuancesDto));