




































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    source: {
      type: String as PropType<'Glossary' | 'reset'>,
      default: 'Glossary'
    },
  }})
