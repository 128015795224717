import { getOneById } from "@/service/mocks";
import { rest } from "msw";
import { Invitation, InvitationCreateDto, InvitationPatchDto, LoginResponse, UserDto } from "../user.service.dto";
import { usersData } from "./data";

interface LoginRequestDto {
  username: string;
  password: string;
}

export const userHandlers = [
  rest.get<any, UserDto>("*/api/auth/user", (req, res, ctx) => {
    const authToken = req.headers.get("Authorization");
    if (authToken && authToken.startsWith("Bearer mock-access-token")) {
      const userId = authToken.split("|")[1];

      const user = getOneById(usersData, userId);

      if (!user.item)
        return res(ctx.status(403));

      return res(ctx.status(200), ctx.json({
        firstName: user.item.firstName,
        lastName: user.item.lastName,
        id: user.item.id,
        role: user.item.role
      }));
    } else {
      return res(ctx.status(403));
    }
  }),

  rest.post<LoginRequestDto, LoginResponse>(
    "*/api/auth/login",
    (req, res, ctx) => {

      const user = usersData.find(x => x.username == req.body.username && x.password == req.body.password)

      if (user) {
        const payload: LoginResponse = {
          accessToken: {
            token: `mock-access-token|${user.id}`,
            expiresAt: new Date("12-12-2100 00:00:00"),
          },
          refreshToken: {
            token: "mock-refresh-token",
          },
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            id: user.id,
            role: user.role
          },
        };
        return res(ctx.status(200), ctx.json(payload));
      } else {
        return res(ctx.status(403));
      }
    }
  ),

  rest.post<InvitationCreateDto, Invitation>(
    "*/api/auth/invitations",
    (req, res, ctx) => {

      const user = usersData.find(x => x.id == req.body.userId)

      if (user) {
        const payload: Invitation = {
          id: `mock-token-${user.id}`,
          accepted: false
        };
        user.invitation = payload;
        return res(ctx.status(200), ctx.json(payload));
      } else {
        return res(ctx.status(403));
      }
    }
  ),

  rest.delete<undefined, Invitation>("*/api/auth/invitations/:id", (req, res, ctx) => {
    const user = usersData.find(x => x.invitation?.id == req.params.id)

    if (user) {
      delete user.invitation;
      return res(ctx.status(200));
    } else {
      return res(ctx.status(403));
    }
  }
  ),

  rest.patch<InvitationPatchDto, undefined>("*/api/auth/invitations/:id", (req, res, ctx) => {
    const user = usersData.find(x => x.invitation?.id == req.params.id)

    if (user && user.invitation && !user.invitation.accepted) {
      user.invitation.accepted = true;
      user.password = req.body.password;
      return res(ctx.status(200));
    } else {
      return res(ctx.status(404));
    }
  }
  ),
];
