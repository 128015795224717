import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { anonymous, setup as initAuth, state as userState } from "@/service/user/user.service";
import { plugin as ValidationPlugin } from "./plugins/validation"
import vuetify from "./plugins/vuetify";
import { i18n } from "./plugins/i18n"
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import { init as initApi } from "@/service";
import { abilitiesPlugin } from '@casl/vue';
import { ability } from "@/service/user/ability"

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

async function run() {

  const useMockAPI = false;

  if (process?.env?.NODE_ENV == "development") {
    await initApi("https://localhost:5001", useMockAPI)
  }
  else {
    await initApi("", false)
  }

  await initAuth(router);

  Vue.config.productionTip = false;

  Vue.use(ValidationPlugin);
  Vue.use(abilitiesPlugin, ability)

  new Vue({
    router,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
}

run();
