
import { getOneById } from '@/service/mocks';
import { rest } from 'msw'
import { CompanyAnalysisDto, CompanyDto } from "../companies.service.dto";
import { companiesData } from "./data";
import * as faker from "faker";


export const handlers = [
    
    rest.get<undefined, Array<CompanyDto>>("*/api/companies", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(companiesData))
    }),

    rest.get<undefined, CompanyAnalysisDto>("*/api/companies/:id/analysis", (req, res, ctx) => {
        const company = getOneById(companiesData, req.params.id);

        if (!company.item)
            return res(ctx.status(404));

        const analysis: CompanyAnalysisDto = {
            ...company.item,
            directors:{
                total: faker.datatype.number({min: 0, max: 1000})
            },
            shareHolders:{
                total: faker.datatype.number({min: 0, max: 1000})
            },
            sharesIssuances:{
                total: faker.datatype.number({min: 0, max: 1000})
            },
            optionsIssuances:{
                total: faker.datatype.number({min: 0, max: 1000})
            }
        }
        
        return res(ctx.status(200), ctx.json(analysis))
    })
];