import faker from "faker";
import { fakeIdGenerator, getOneRandom } from "@/service/mocks";
import { StakeholderDto, StakeholderType } from "../stakeholders.service.dto";
import { optionsIssuanceData } from "@/service/options-issuances/__mocks__/data";
import { usersData } from "@/service/user/__mocks__/data";
import { sharesIssuancesData } from "@/service/shares-issuances/__mocks__/data";

export const stakeHolderId = fakeIdGenerator();

export const stakeholdersData: Array<StakeholderDto> = new Array(100).fill({}).map(x => ({
  id: stakeHolderId(),
  name: faker.company.companyName(),
  isBoardMember: faker.datatype.boolean(),
  isEmployee: faker.datatype.boolean(),
  type: getOneRandom<StakeholderType>(["company", "individual"]),
  vatNumber: faker.lorem.word(10),
  taxOffice: faker.lorem.word(200),
  user: getOneRandom(usersData),
  contact: {
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumber(),
    address: {
      id: faker.datatype.uuid(),
      city: faker.address.city(),
      country: faker.address.country(),
      streetAddress: faker.address.streetAddress(),
      state: faker.address.state(),
      postCode: faker.address.zipCode()
    }
  },
  sharesTotal: faker.datatype.number({ min: 0, max: 1000 }),
  shares: [
    getOneRandom(sharesIssuancesData)
  ],
  optionsTotal: faker.datatype.number({ min: 0, max: 1000 }),
  options: [
    getOneRandom(optionsIssuanceData)
  ],
  isCompany: faker.datatype.boolean(),
  crnNumber: faker.datatype.string()
} as StakeholderDto));