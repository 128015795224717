<template>
  <v-container>
    <h2 class="mb-5">Login</h2>
    <h4 class="mb-4">Enter your credentials</h4>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="username"
            :rules="usernameRules"
            label="Email"
            required
            outlined
            placeholder=" "
            auto
            autocomplete="username"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="password"
            type="password"
            :rules="passwordRules"
            label="Password"
            required
            outlined
            placeholder=" "
            autocomplete="current-password"
          />
          <router-link :to="{ path: '/request-reset-password' }">
            {{ $t('forgotPassword') }}
          </router-link>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-alert
            v-if="error"
            dense
            type="error"
          >
            {{ error }}
          </v-alert>
          <v-btn
            :disabled="!valid"
            color="primary"
            depressed
            class="mr-4"
            :loading="loading"
            @click="login"
          >
            Login
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import Vue from "vue";
import * as auth from "@/service/user/user.service";

export default Vue.extend({
  props: {
    nextUrl: {
      required: false,
      type: String,
      default: "/",
    },
  },
  data() {
    return {
      loading: false,
      error: "",
      valid: false,
      usernameRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length > 5) || "Password must be more than 5 characters",
      ],
      username: "",
      password: "",
    };
  },
  mounted() {
    if (auth.state.user.role != "anonymous") {
      this.$router.push("/");
    }
  },
  methods: {
    async login() {
      try {
        this.loading = true;
        this.error = "";
        const result = await auth.login(this.username, this.password);
        if (result) {
          this.$router.push(this.nextUrl);
        } else {
          this.error = "Invalid credentials";
        }
      } catch (error) {
        this.error = "Something went wrong";
      }
      this.loading = false;
    },
  },
});
</script>
