import { DocumentDto, DocumentType, DocumentStatus } from "../documents.service.dto";
import { documentId } from "./data";
import { documentsData } from "./data";

export function createDocumentFromFile(file: File, documentType: DocumentType, documentStatus: DocumentStatus): DocumentDto {

  const item = {
    mimeType: file.type,
    name: file.name,
    type: documentType,
    id: documentId(),
    createdDate: new Date(),
    status: documentStatus
  }

  documentsData.push(item);

  return item;
}