
import { getOneById } from '@/service/mocks';
import { rest } from 'msw'
import { ValuationCreateDto, ValuationDto } from "../valuations.service.dto";
import { valuationsData, valuationId } from "./data";
import { createDocumentFromFile } from '@/service/documents/__mocks__/helpers';

export const valuationsHandlers = [
    
    rest.get<undefined, Array<ValuationDto>>("*/api/valuations", (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(valuationsData))
    }),

    rest.post<ValuationCreateDto, string>("*/api/valuations", (req, res, ctx) => {
        const id = valuationId();
        valuationsData.push({
            id: id,
            createdDate: new Date(),
            files: [],
            unrestrictedMarketValue: 0,
            actualMarketValue: 0,
            status: "pending",
            type: req.body.type            
        })

        return res(ctx.status(201), ctx.json(id))
    }),

    rest.post<any, undefined>("*/api/valuations/:id/documents", (req, res, ctx) => {
        
        const valuation = getOneById(valuationsData, req.params.id);
        if (!valuation.item)
            return res(ctx.status(404));

        valuation.item.files.push(createDocumentFromFile(req.body.file, "Other", "pending"));

        return res(ctx.status(201))
    }),

];