import { fakeIdGenerator, getManyRandom, getOneRandom } from "@/service/mocks";
import * as faker from "faker";

import { documentsData } from "@/service/documents/__mocks__/data";
import { UserDto } from "../user.service.dto";

export const userId = fakeIdGenerator();

interface UserModel extends UserDto {
    username: string
    password: string
}

export const usersData: Array<UserModel> = [
    {
        id: userId(),
        firstName: "Test",
        lastName: "Manager",
        role: "manager",
        username: "manager@test.com",
        password: "P@ssw0rd"
    },
    {
        id: userId(),
        firstName: "Test",
        lastName: "Stakeholder",
        role: "stakeholder",
        username: "stakeholder@test.com",
        password: "P@ssw0rd"
    },
    {
        id: userId(),
        firstName: "Test",
        lastName: "Viewer",
        role: "viewer",
        username: "viewer@test.com",
        password: "P@ssw0rd"
    },
    {
        id: userId(),
        firstName: "Test",
        lastName: "Back Office",
        role: "backoffice",
        username: "backoffice@test.com",
        password: "P@ssw0rd"
    }
];