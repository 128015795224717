import { setupWorker } from "msw";
import * as faker from "faker";
import { handlers as companyHandlers } from "./companies/__mocks__/handlers";
import { stakeHoldersHandlers } from "./stakeholders/__mocks__/handlers";
import { userHandlers } from "./user/__mocks__/handlers";
import { optionsPoolshandlers } from "./options-pools/__mocks__/handlers";
import { optionsIssuancesHandlers } from "./options-issuances/__mocks__/handlers";
import { sharesIssuanceshandlers } from "@/service/shares-issuances/__mocks__/handlers";
import { documentsHandlers } from "@/service/documents/__mocks__/handlers";
import { shareClassesHandlers } from "@/service/share-classes/__mocks__/handlers";
import { valuationsHandlers } from "@/service/valuations/__mocks__/handlers";
import { Resource } from ".";


export async function startWorker() {

    console.debug("Starting worker...");

    const handlers = [
        ...companyHandlers,
        ...stakeHoldersHandlers,
        ...userHandlers,
        ...optionsPoolshandlers,
        ...optionsIssuancesHandlers,
        ...sharesIssuanceshandlers,
        ...documentsHandlers,
        ...shareClassesHandlers,
        ...valuationsHandlers
    ];

    const worker = setupWorker(...handlers);

    return worker.start({
        onUnhandledRequest: "bypass"
    });

}

export function fakeIdGenerator(): () => string {
    let lastId = 0;
    return function (): string {
        lastId++;
        return lastId.toString();
    }
}

export function getOneRandomOrDefault<T>(data: Array<T>): T | undefined {
    return data.length ?
        data[faker.datatype.number({ min: 0, max: data.length - 1 })] :
        undefined
}

export function getOneRandom<T>(data: Array<T>): T {
    if (!data.length)
        throw new Error("Collection is empty")

    return data[faker.datatype.number({ min: 0, max: data.length - 1 })];
}

export function getManyRandom<T>(data: Array<T>, count: number): Array<T> {
    if (data.length < count)
        throw new Error("Collection is smaller than specified count")

    const picks: Array<number> = []

    while (picks.length < count) {
        const pick = faker.datatype.number({ min: 0, max: data.length - 1 })
        if (picks.indexOf(pick) == -1)
            picks.push(pick);
    }

    return picks.map(x => data[x]);
}

interface RetrievedResource<T> {
    index: number
    item?: T
}
export function getOneById<T>(data: Array<T>, id: string): RetrievedResource<T> {
    const index = (data as unknown as Array<Resource>).findIndex(x => x.id == id);
    return index == - -1 ? { index: -1, item: undefined } : { index, item: data[index] };
}