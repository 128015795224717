import { Store } from "@/core"
import { UserDto } from "./service/user/user.service";
import { ability } from "@/service/user/ability"
import { subject } from "@casl/ability";

type Color = "primary" | "secondary" | "accent" | "info" | "success" | "warning" | "error";
type Module = 'my-dashboard' | 'company' | 'options' | 'cap-table' | 'documents' | 'stakeholders' | 'admin' | 'guides'

interface AppStoreData {
  user?: UserDto,
  snackbar: {
    visible: boolean,
    text: string,
    timeout: number,
    color: Color
  },
  modules: Array<Module>
}

class AppStore extends Store<AppStoreData> {

  snackbar(text: string, color: Color = "info") {
    this.state.snackbar.text = text;
    this.state.snackbar.visible = true;
    this.state.snackbar.color = color;
  }

  setUser(user: UserDto) {
    this.state.user = user;
    this.invalidateModules();
  }

  invalidateModules() {
    console.log("invalidateModules");
    this.state.modules = [];

    if (ability.can('read', subject("Module", { type: "my-dashboard" })))
      this.state.modules.push("my-dashboard");

    if (ability.can('read', subject("Module", { type: "company" })))
      this.state.modules.push("company");

    if (ability.can('read', subject("Module", { type: "options" })))
      this.state.modules.push("options");

    if (ability.can('read', subject("Module", { type: "cap-table" })))
      this.state.modules.push("cap-table");

    if (ability.can('read', subject("Module", { type: "documents" })))
      this.state.modules.push("documents");

    if (ability.can('read', subject("Module", { type: "stakeholders" })))
      this.state.modules.push("stakeholders");

    if (ability.can('read', subject("Module", { type: "admin" })))
      this.state.modules.push("admin");

    if (ability.can('read', subject("Module", { type: "guides" })))
      this.state.modules.push("guides");
  }
}

export const appStore = new AppStore({
  modules: [],
  snackbar: {
    visible: false,
    text: "",
    timeout: 3000,
    color: "info"
  }
})