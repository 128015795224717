






























































import Vue from "vue";
import * as auth from "@/service/user/user.service";
import { appStore } from "@/app.store";

export default Vue.extend({
  data() {
    return {
      username: '',
      loading: false,
      error: "",
      valid: false,
      usernameRules: [
        (v: any) => !!v || "E-mail is required",
        (v: any) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    async requestReset() {
      if (!this.username)
        return;

      try {
        this.loading = true;
        await auth.sendResetPasswordNotification({
          username: this.username,
          resetUrl: `${location.origin}/reset-password?token={token}&email={email}`
        });

        appStore.snackbar(this.$t("shared.successfulExecution").toString(), "success");
        this.$router.replace("/login");
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
      finally {
        this.loading = false;
      }
    }
  },
});
