export enum FilterLogic {
    and,
    or
}

export enum FilterCondition {
    equals,
    contains,
    containsCaseInsensitive,
    greaterThan
}

export interface PageFilter {
    field: string
    value: string
    condition: FilterCondition
}

export interface SortOption {
    field: string;
    asc: boolean;
}

export interface PageQuery {
    page: number;
    size: number;
    filterLogic?: FilterLogic;
    filters?: Array<PageFilter>;
    sorting?: Array<SortOption>;
    include?: Array<string>;
}

export function encodeAsQueryParam(value: PageQuery): string {
    return btoa(unescape(encodeURIComponent(JSON.stringify(value, null, 0))))
}