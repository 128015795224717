import { rest } from 'msw'
import { documentsData } from "@/service/documents/__mocks__/data";
import { DocumentDto } from '../documents.service.dto';
import { getOneById } from "@/service/mocks";
import { Entity } from '../documents.service';
import { valuationsData } from "@/service/valuations/__mocks__/data";

export const documentsHandlers = [

  rest.get<undefined, Array<DocumentDto>>("*/api/documents", (req, res, ctx) => {

    const filterQueryParam = req.url.searchParams.get("filter");
    if (!filterQueryParam)
      return res(ctx.status(400));

    const [entity, entityId] = filterQueryParam.split(',') as [Entity, string]

    // Filter logic
    switch (entity) {
      case "company":
        return res(ctx.status(200), ctx.json(documentsData));
      case "valuation":
        return res(ctx.status(200), ctx.json(documentsData.filter(doc => valuationsData.find(x => x.id == entityId)?.files.some(x => x.id == doc.id))));
      default:
        return res(ctx.status(400));
    }

  }),

  rest.get<undefined, DocumentDto>("*/api/documents/:id", (req, res, ctx) => {
    const result = getOneById(documentsData, req.params.id);
    if (!result.item)
      return res(ctx.status(404));
    else
      return res(ctx.status(200), ctx.json(result.item));
  }),

];

